<template>
  <transition>
    <div class="locker_setting_list sub_new_style01 sub_ui_box1">
      <table class="table_form line-bin">
        <colgroup>
          <col style="width: auto" />
          <col style="width: auto" />
          <col style="width: auto" />
        </colgroup>
        <tbody style="text-align-last: center">
          <tr>
            <td>
              <label for="label01">사이트 설정</label>
            </td>
            <td>
              <label for="label01">수신시 설정</label>
            </td>
            <td>
              <label for="label02">발신시 설정</label>
            </td>
          </tr>
          <tr>
            <td>
              <DxSelectBox
                id="label01"
                v-model="siteId"
                placeholder="사이트 설정"
                :items="infoPushSiteList"
                display-expr="siteNm"
                value-expr="siteId"
                :styling-mode="stylingMode"
                :height="30"
              />
            </td>
            <td>
              <DxSelectBox
                id="label01"
                v-model="inContentId"
                placeholder="수신시 설정"
                :items="contentList"
                display-expr="description"
                value-expr="id"
                :styling-mode="stylingMode"
                :height="30"
              />
            </td>
            <td>
              <DxSelectBox
                id="label02"
                v-model="outContentId"
                placeholder="발신시 설정"
                :items="contentList"
                display-expr="description"
                value-expr="id"
                :styling-mode="stylingMode"
                :height="30"
              />
            </td>
          </tr>
        </tbody>
      </table>
      <esp-dx-data-grid :data-grid="dataGrid" ref="modalUserGridRef" />
    </div>
  </transition>
</template>

<script>
  import { DxSelectBox } from 'devextreme-vue/select-box';
  import { isSuccess } from '@/plugins/common-lib';
  import CustomStore from 'devextreme/data/custom_store';
  import EspDxDataGrid from '@/components/devextreme/esp-dx-data-grid.vue';

  let vm = this;

  export default {
    components: {
      EspDxDataGrid,
      DxSelectBox,
    },
    props: {
      sendData: Object,
    },
    watch: {
      infoPushSiteList: function (newVal) {
        if (newVal.length) {
          this.siteId = newVal[0].siteId;
        }
      },
      siteId: function (newVal) {
        this.contentList = [{ id: null, description: '미지정' }, ...this.contentList.filter(item => item.siteId === newVal)];
      },
    },
    computed: {
      /** @description: 부서 타이틀 가져오기 */
      deptTitles() {
        return this.$_getCode('euc_dept_title').filter(d => d.delFl === 'N');
      },
    },
    data() {
      return {
        infoPushSiteList: [],
        userData: null,
        contentList: [],
        siteId: null,
        inContentId: null,
        outContentId: null,
        stylingMode: 'outlined', //outlined, underlined, filled
        dataGrid: {
          refName: 'modalUserGridRef',
          allowColumnResizing: true, //컬럼 사이즈 허용
          showBorders: false, //border 유무
          showColumnHeaders: true, //컬럼 헤더 유무
          showColumnLines: false, //컬럼 세로선 유무
          showRowLines: true, //컬럼 가로선 유무
          rowAlternationEnabled: false,
          dataSource: [],
          height: '450px',
          apiActionNm: {},
          showActionButtons: {
            customButtons: [],
          },
          grouping: {
            contextMenuEnabled: false,
            autoExpandAll: false,
            allowCollapsing: true,
            expandMode: 'rowClick', // rowClick or buttonClick
          },
          groupPanel: {
            visible: false,
          },
          columnChooser: {
            enabled: false, // 컬럼 Chooser 버튼 사용유무
          },
          loadPanel: {
            enabled: false, // 로딩바 표시 유무
          },
          sorting: {
            mode: 'multiple', // single multiple
          },
          remoteOperations: {
            // 서버사이드 여부
            filtering: true,
            sorting: true,
            grouping: true,
            paging: true,
          },
          paging: {
            // scrolling 미사용시만 적용됨
            enabled: true,
            pageSize: 10,
            pageIndex: 0, // 시작페이지
          },
          scrolling: {
            mode: 'standard', //스크롤 모드 : ['infinite', 'standard', 'virtual']
          },
          pager: {
            visible: true, //페이저 표시 여부
            showPageSizeSelector: false, //페이지 사이즈 선택버튼 표시 여부
            allowedPageSizes: [5, 10, 15, 20], //페이지 사이즈 선택 박스
            displayMode: 'compact', //표시 모드 : ['full', 'compact']
            showInfo: true, //페이지 정보 표시 여부 : full인 경우만 사용 가능
            showNavigationButtons: true, //페이지 네비게이션(화살표) 버튼 표시 여부 : full인 경우만 사용 가능
          },
          filterRow: {
            visible: true,
          },
          headerFilter: {
            visible: true,
          },
          editing: {
            mode: 'batch', //수정 모드: ['row', 'cell', 'batch']
            startEditAction: 'click', //셀 편집 상태로 변경 할 이벤트 타입 : ['click', 'dbclick'] / 'cell', 'batch' 모드인 경우에만 가능
            selectTextOnEditStart: true, //셀 수정시 텍스트 전체 선택 여부
          },
          selecting: {
            mode: 'multiple', //행 단일/멀티 선택 타입 : ['single', 'multiple']
            selectAllMode: 'page', //행 선택 허용 범위 : ['allPages', 'page']
            showCheckBoxesMode: 'always', //행 선택 모드 : ['none', 'onClick', 'onLongTap', 'always']
          },
          columns: [
            {
              caption: '사번',
              dataField: 'userNo',
              height: 40,
              alignment: 'center', // left center right
              visible: true,
              allowEditing: false,
              sortOrder: 'none', // acs desc none
              allowHeaderFiltering: false,
              allowExporing: true,
              fixed: false, // 컬럼 fix 시 사용
              fixedPosition: 'left', // left or right
            },
            {
              caption: '이름',
              dataField: 'userNm',
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: true,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowGrouping: false,
              allowExporing: true,
            },
            {
              caption: '내선번호',
              dataField: 'ext',
              calculateCellValue: this.ext,
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: true,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowGrouping: false,
              allowExporing: true,
            },
            {
              //caption: '부서1',
              dataField: 'deptNmDepth1',
              calculateCellValue: rowData => this.deptNmDepth(rowData, 'deptNmDepth1'),
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: true,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowGrouping: false,
              allowExporing: true,
              headerCellTemplate: container => {
                let div = document.createElement('div');
                div.innerHTML = `<b>${this.deptTitleByDepth('depth1')}</b>`;
                container.append(div);
              },
            },
            {
              //caption: '부서2',
              dataField: 'deptNmDepth2',
              calculateCellValue: rowData => this.deptNmDepth(rowData, 'deptNmDepth2'),
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: true,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowGrouping: false,
              allowExporing: true,
              headerCellTemplate: container => {
                let div = document.createElement('div');
                div.innerHTML = `<b>${this.deptTitleByDepth('depth2')}</b>`;
                container.append(div);
              },
            },
            {
              //caption: '부서3',
              dataField: 'deptNmDepth3',
              calculateCellValue: rowData => this.deptNmDepth(rowData, 'deptNmDepth3'),
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: true,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowGrouping: false,
              allowExporing: true,
              headerCellTemplate: container => {
                let div = document.createElement('div');
                div.innerHTML = `<b>${this.deptTitleByDepth('depth3')}</b>`;
                container.append(div);
              },
            },
            {
              //caption: '부서4',
              dataField: 'deptNmDepth4',
              calculateCellValue: rowData => this.deptNmDepth(rowData, 'deptNmDepth4'),
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: true,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowGrouping: false,
              allowExporing: true,
              headerCellTemplate: container => {
                let div = document.createElement('div');
                div.innerHTML = `<b>${this.deptTitleByDepth('depth4')}</b>`;
                container.append(div);
              },
            },
          ],
        },
      };
    },
    methods: {
      getSelectedUserData() {
        return this.$refs.modalUserGridRef.selectedRowsData;
      },
      /** @description: 부서 출력 */
      deptNmDepth(rowData, deptNmDepth) {
        return rowData[deptNmDepth] ?? '-';
      },
      async selectDataList(sort = '-regDt') {
        vm = this;
        this.dataGrid.dataSource = new CustomStore({
          key: 'id',
          load: async loadOptions => {
            let params = this.$_getDxDataGridParam(loadOptions);
            if (!params.sort) {
              params.sort = sort;
            }

            if (this.userData?.userNo) {
              params.userNo = this.userData.userNo;
            }

            const payload = {
              data: params,
              actionname: 'USER_LIST_ALL_VIEW',
              loading: false,
            };

            const rtnData = {
              data: [],
              totalCount: 0,
            };

            const res = await this.CALL_API(payload);
            if (isSuccess(res)) {
              rtnData.data = res.data.data;
              rtnData.totalCount = res.data.header.totalCount;
            }
            return rtnData;
          },
        });
      },
      async getInfoPushSiteList() {
        const payload = {
          actionname: 'INFOPUSH_SITE_LIST_ALL',
          data: {
            params: {
              viewCd: 1,
              sort: '+siteOrd',
            },
          },
        };
        const res = await this.CALL_API(payload);
        if (isSuccess(res)) {
          if (res.data.data.length) {
            this.infoPushSiteList = res.data.data;
          } else {
            this.infoPushSiteList = [];
          }
        }
      },
      /** @description: 뎁스별 부서 타이틀 */
      deptTitleByDepth(depth) {
        return this.deptTitles.find(d => d.codeValue === depth).codeNm;
      },
      createdData() {
        this.contentList = this.sendData.contentList;
        this.userData = this.sendData.userData;
        this.getInfoPushSiteList();
      },
      mountedData() {
        this.selectDataList();
      },
    },
    created() {
      this.createdData();
    },
    mounted() {
      this.mountedData();
    },
  };
</script>